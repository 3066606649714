import { Component, AfterViewInit, Input, Output, EventEmitter, ElementRef, ViewChild, OnDestroy } from '@angular/core';
import Uppy   from '@uppy/core';
import type { UppyFile, Meta, Body } from '@uppy/utils/lib/UppyFile';
import type { Uppy as UppyType } from '@uppy/core';

import Dashboard from '@uppy/dashboard';
import Tus from '@uppy/tus';
import pt_BR from '@uppy/locales/lib/pt_BR';
import { environment } from 'apps/vertice/src/environments/environment';
import { FileUploadQuery } from '../file-upload.query';
import { SupabaseService } from 'apps/vertice/src/app/shared/services/supabase.service';
import { Subject, BehaviorSubject, from, tap, retry, of, delay, catchError, takeUntil, Observable, switchMap, take, EMPTY, filter } from 'rxjs';
import { AuthState, RefreshToken } from '@vertice/state';
import { Select, Store } from '@ngxs/store';


// v3

interface UppyCustomMeta extends Meta {
  storedFileName?: string;
  bucketName?: string;
  objectName?: string;
  contentType?: string;
  cacheControl?: number;
}

@Component({
  selector: 've-file-upload',
  templateUrl: './file-upload.component.html',
})
export class FileUploadComponent implements AfterViewInit, OnDestroy {
  @Input() bucketName: string = 'broadcast-attachments';
  @Output() fileUploaded = new EventEmitter<{ fileName: string, fileUrl: string }>();
  @ViewChild('uppyDashboard') uppyDashboardElement!: ElementRef;
  
  @Select(AuthState.isAuthenticated) isAuthenticated$!: Observable<boolean>;
  @Select(AuthState.getToken) token$!: Observable<string | null>;
  
  private uppy!: UppyType<Meta, Body>;
  private destroy$ = new Subject<void>();
  private sessionInitialized$ = new BehaviorSubject<boolean>(false);
  private initializationAttempts = 0;
  private readonly MAX_RETRY_ATTEMPTS = 3;
  private readonly RETRY_DELAY = 1000; // 1 second


  private handleFileAdded = (file: UppyFile<UppyCustomMeta, Body>) => {
    if (!file?.name) return;
    const timestamp = Date.now();
    const cleanFileName = file.name.trim().replace(/\s+/g, '-');
    const fileName = `${timestamp}-${cleanFileName}`;

    // CHANGED: Using proper typing for meta
    const customMeta: UppyCustomMeta = {
      bucketName: this.bucketName,
      objectName: fileName,
      contentType: file.type || 'application/octet-stream',
      cacheControl: 3600,
      storedFileName: fileName
    };

    file.meta = { ...file.meta, ...customMeta };
  };

  private handleUploadSuccess = (file: UppyFile<UppyCustomMeta, Body> | undefined, response: { body?: Body, status: number }) => {
    if (!file?.meta?.storedFileName) return;

    const publicUrl = this.supabase.getFilePublicUrl(
      this.bucketName,
      file.meta.storedFileName
    );

    this.fileUploaded.emit({
      fileName: file.meta.storedFileName,
      fileUrl: publicUrl
    });
  };



  constructor(private supabase: SupabaseService, private store: Store,) { }

  // New simplified session initialization with proper error handling and retries using AuthState
  ngAfterViewInit() {
    this.isAuthenticated$
      .pipe(
        take(1),
        switchMap(isAuthenticated => {
          if (isAuthenticated) {
            return this.token$.pipe(take(1));
          }
          // If not authenticated, try to refresh
          return this.store.dispatch(new RefreshToken()).pipe(
            switchMap(() => this.token$.pipe(take(1)))
          );
        }),
        tap(token => {
          if (!token) throw new Error('No valid auth token found');
        }),
        retry(3), // Simple retry 3 times
        catchError(error => {
          console.error('Failed to initialize session:', error);
          return EMPTY;
        }),
        filter((token): token is string => !!token),
        takeUntil(this.destroy$)
      )
      .subscribe(token => {
        this.initializeUppy(token);
        this.sessionInitialized$.next(true);
      });
  }

  private initializeUppy(accessToken: string) {
    if (this.uppy) {
      this.uppy.destroy();
    }

    this.uppy = new Uppy<Meta, Body>({
      debug: true,
      autoProceed: false,
      restrictions: {
        maxFileSize: 10 * 1024 * 1024,
        maxNumberOfFiles: 1,
        allowedFileTypes: ['image/*', 'video/*', 'audio/*', '.pdf', '.doc', '.docx']
      }
    })
      .use(Dashboard, {
        inline: true,
        target: this.uppyDashboardElement.nativeElement,
        height: 300,
        width: '100%',
        showProgressDetails: true,
        locale: pt_BR
      })
      .use(Tus, {
        endpoint: `${environment.supabaseUrl}/storage/v1/upload/resumable`,
        retryDelays: [0, 3000, 5000, 10000, 20000],
        chunkSize: 6 * 1024 * 1024,
        headers: {
          authorization: `Bearer ${accessToken}`,
          'x-upsert': 'true'
        },
        uploadDataDuringCreation: true,
        removeFingerprintOnSuccess: true
      });

    this.setupListeners();
  }

  private handleUploadError = (file: UppyFile<UppyCustomMeta, Body> | undefined, error: any, response?: any) => {
    console.error('Upload error:', {
      file,
      error: error?.message || 'Unknown error',
      response: {
        status: response?.status,
        error: response?.body?.error || 'Unknown error'
      }
    });
  };

  private setupListeners(): void {
    if (!this.uppy) return;

    // CHANGED: Using properly typed event handlers
    this.uppy
      .on('file-added', this.handleFileAdded)
      .on('upload-success', this.handleUploadSuccess)
      .on('upload-error', this.handleUploadError);
  }

  private cleanupUppy(): void {
    if (this.uppy) {
      // CHANGED: Using properly typed event handlers
      this.uppy.off('file-added', this.handleFileAdded)
        .off('upload-success', this.handleUploadSuccess)
        .off('upload-error', this.handleUploadError);
      this.uppy.destroy();
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
    this.cleanupUppy();
  }
}



// !v1 original prior to service key change
// export class FileUploadComponent implements AfterViewInit {
//   @Input() bucketName: string = 'broadcast-attachments';
//   @Output() fileUploaded = new EventEmitter<{ fileName: string, fileUrl: string }>();
//   @ViewChild('uppyDashboard') uppyDashboardElement!: ElementRef;

//   private uppy!: Uppy;
  
//   constructor(private fileUploadQuery: FileUploadQuery, private supabase: SupabaseService,) { }

//   ngAfterViewInit() {
//     this.initializeUppy();
//   }

//   initializeUppy() {
//     // Debug: Check authentication status
//     const { data: { session }, error } = await this.supabase.supabase.auth.getSession();

//     if (!session) {
//       console.error('No active session!');
//       return;
//     }

//     console.log('Authenticated with user:', session.user?.id);
//     console.log('Access token:', session.access_token);

//     const supabaseStorageURL = `${environment.supabaseUrl}/storage/v1/upload/resumable`;

//     this.uppy = new Uppy({
//       debug: true,
//       autoProceed: false,
//       restrictions: {
//         maxFileSize: 10 * 1024 * 1024, // 10MB
//         maxNumberOfFiles: 1,
//         allowedFileTypes: ['image/*', 'video/*', 'audio/*', '.pdf', '.doc', '.docx']
//       }
//     })
//       .use(Dashboard, {
//         inline: true,
//         target: this.uppyDashboardElement.nativeElement,
//         height: 300,
//         width: '100%',
//         showProgressDetails: true,
//         locale: pt_BR,
//       })
//       .use(Tus, {
//         endpoint: supabaseStorageURL,
//         headers: {
//           // 'Authorization': `Bearer ${environment.supabaseServiceKey}`,
//           'Authorization': `Bearer ${session.access_token}`, // Use session token instead of anon key
//           // 'Authorization': `Bearer ${environment.supabaseAnonKey}`,
//           'x-upsert': 'true',
//           'x-client-info': 'supabase-js/2.x',  // Add this header
//           'Content-Type': 'application/offset+octet-stream' 
//         },
//         chunkSize: 6 * 1024 * 1024,
//         allowedMetaFields: ['bucketName', 'objectName', 'contentType', 'cacheControl']
//       });

//     this.uppy.on('file-added', (file) => {
//       // Debug: Log metadata being sent
//       const supabaseMetadata = {
//         bucketName: this.bucketName,
//         objectName: file.name,
//         contentType: file.type,
//       };
//       console.log('File metadata:', supabaseMetadata);

//       file.meta = {
//         ...file.meta,
//         ...supabaseMetadata,
//       };
//     });


//     // v2
//     this.uppy.on('upload-success', (file, response) => {
//       if (file && file.name) {
//         const publicUrl = this.supabase.getFilePublicUrl(this.bucketName, file.name);
//         this.fileUploaded.emit({ fileName: file.name, fileUrl: publicUrl });
//       }
//     });
//   }
// }