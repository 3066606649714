import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Store } from "@ngxs/store";
import { SupabaseService } from "apps/vertice/src/app/shared/services/supabase.service";
import { Observable, switchMap, throwError, from, map, catchError, of } from "rxjs";
import { AuthService } from "../auth";
import { BaseCrudQuery } from "../base-crud.query";
import { UsersService } from "../users";
import { BroadcastContactListWithItems, BroadcastContactLists, StudentDetailsAndCourses } from "@vertice/data";

@Injectable({
    providedIn: 'root'
})
export class BroadcastContactsListsQuery extends BaseCrudQuery<any> {
    constructor(
        store: Store,
        supabaseService: SupabaseService,
        authService: AuthService,
        router: Router,
        userService: UsersService,
    ) {
        super(store, supabaseService, authService, router, userService);
    }


    createBroadcastList(id: string, name: string, students: any[], schemaId?: string): Observable<BroadcastContactLists> {
        return this.userService.getUser().pipe(
            switchMap(user => {
                if (!user) {
                    return throwError(() => new Error('User not authenticated'));
                }
                const newList: Partial<BroadcastContactLists> = {
                    id,
                    name,
                    user_id: user.id,
                    student_ids: students.map(s => s.id),
                    student_count: students.length,
                    created_at: new Date().toISOString(),
                    updated_at: new Date().toISOString()
                };
                return this.create('broadcast_contact_lists', newList).pipe(
                    switchMap(createdList => this.addStudentsToList(createdList.id!, students.map(s => s.id)).pipe(
                        map(() => createdList as BroadcastContactLists)
                    ))
                );
            })
        );
    }
    getBroadcastList(id: string): Observable<BroadcastContactListWithItems> {
        return from(this.supabase
            .from('broadcast_contact_lists')
            .select(`
            *,
            broadcast_contact_list_items (student_id)
        `)
            .eq('id', id)
            .single()
        ).pipe(
            map(response => {
                if (response.error) throw new Error(response.error.message);
                // Changed: Explicitly cast the response data to BroadcastContactListWithItems
                const list = response.data as BroadcastContactListWithItems;
                return {
                    ...list,
                    student_ids: list.broadcast_contact_list_items.map(item => item.student_id)
                };
            })
        );
    }

 

    removeStudentsFromList(listId: string, studentIds: string[]): Observable<void> {
        return from(this.supabase
            .from('broadcast_contact_list_items')
            .delete()
            .eq('contact_list_id', listId)
            .in('student_id', studentIds)
        ).pipe(
            map(() => void 0)
        );
    }

    // Added: Method to update a broadcast list
    updateBroadcastList(listId: string, data: { name: string, students: StudentDetailsAndCourses[] }): Observable<BroadcastContactLists> {
        return this.userService.getUser().pipe(
            switchMap(user => {
                if (!user) {
                    return throwError(() => new Error('User not authenticated'));
                }
                const updateData: Partial<BroadcastContactLists> = {
                    name: data.name,
                    user_id: user.id,
                    student_count: data.students.length,
                    updated_at: new Date().toISOString()
                };
                return this.update('broadcast_contact_lists', updateData, `id.eq.${listId}`).pipe(
                    switchMap(updatedList => this.updateStudentsInList(listId, data.students).pipe(
                        map(() => updatedList[0] as BroadcastContactLists)
                    ))
                );
            })
        );
    }

    private updateStudentsInList(listId: string, students: StudentDetailsAndCourses[]): Observable<void> {
        return this.removeAllStudentsFromList(listId).pipe(
            switchMap(() => this.addStudentsToList(listId, students.map(s => s.id)))
        );
    }

    // Added: New method to remove all students from a list
    private removeAllStudentsFromList(listId: string): Observable<void> {
        return from(this.supabase
            .from('broadcast_contact_list_items')
            .delete()
            .eq('contact_list_id', listId)
        ).pipe(
            map(() => void 0)
        );
    }
    

    // Added: Method to delete a broadcast list
    deleteBroadcastList(listId: string): Observable<any> {
        return this.delete('broadcast_lists', listId);
    }

    addStudentsToList(listId: string, studentIds: string[]): Observable<void> {
        const items = studentIds.map(studentId => ({ contact_list_id: listId, student_id: studentId }));
        return from(this.supabase.from('broadcast_contact_list_items').insert(items)).pipe(
            map(() => void 0)
        );
    }

    getAllBroadcastLists(): Observable<BroadcastContactLists[]> {
        return this.getAll('broadcast_lists');
    }


    getSingleBroadcastList(id: string): Observable<BroadcastContactListWithItems | null> {
        console.log('***CONTACTS LIST QUERY*** Getting single broadcast list, id:', id);
        if (!id) {
            console.error('***CONTACTS LIST QUERY*** Invalid id provided');
            return of(null);
        }
        return from(this.supabase
            .from('broadcast_contact_lists')
            .select(`
      *,
      broadcast_contact_list_items (student_id)
    `)
            .eq('id', id)
            .single()
        ).pipe(
            map(response => {
                if (response.error) {
                    console.error('***CONTACTS LIST QUERY*** Error fetching list:', response.error);
                    // throw new Error(response.error.message);
                    return null;

                }
                console.log('***CONTACTS LIST QUERY*** List fetched successfully');
                const list = response.data as BroadcastContactListWithItems;
                return {
                    ...list,
                    student_ids: list.broadcast_contact_list_items.map(item => item.student_id)
                };
            }),
            catchError(error => {
                console.error('***CONTACTS LIST QUERY*** Error in getSingleBroadcastList:', error);
                return of(null);
            })
        );
    }
    // v1
    // getSingleBroadcastList(id: string): Observable<BroadcastContactListWithItems> {
    //     return from(this.supabase
    //         .from('broadcast_contact_lists')
    //         .select(`
    //             *,
    //             broadcast_contact_list_items (student_id)
    //         `)
    //         .eq('id', id)
    //         .single()
    //     ).pipe(
    //         map(response => {
    //             if (response.error) throw new Error(response.error.message);
    //             return response.data as BroadcastContactListWithItems;
    //         })
    //     );
    // }


    getBroadcastListWithStudents(listId: string): Observable<BroadcastContactLists & { students: StudentDetailsAndCourses[] }> {
        return from(this.supabase
            .from('broadcast_contact_lists')
            .select(`
                *,
                broadcast_contact_list_items (
                    student:student_id (*)
                )
            `)
            .eq('id', listId)
            .single()
        ).pipe(
            map(response => {
                if (response.error) throw response.error;
                const list = response.data as BroadcastContactLists;
                const students = (response.data.broadcast_contact_list_items || []).map((item: any) => item.student);
                return { ...list, students };
            })
        );
    }









    getBroadcastListsWithAssociatedBroadcasts(): Observable<any[]> {
        return from(this.supabase
            .from('broadcast_lists')
            .select(`
        *,
        broadcast_list_items (
          student:student_id (*)
        ),
        broadcasts (*)
      `)
        ).pipe(
            map(response => {
                if (response.error) throw response.error;
                return response.data;
            })
        );
    }

    getBroadcastListsWithAssociatedCounts(): Observable<BroadcastContactLists[]> {
        // Comment: Fetching lists with associated counts using a custom RPC function
        return from(this.supabase.rpc('get_broadcast_lists_with_counts')).pipe(
            map((response: any) => {
                if (response.error) throw new Error(response.error.message);
                return response.data;
            })
        );
    }
}