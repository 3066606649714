import { Injectable } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { Navigate } from '@ngxs/router-plugin';
import { HttpClient } from '@angular/common/http';

import {
  AuthState,
  Authenticate,
  ClearToken,
  Logout,
  SetToken,
  SetUser,
  UnsetUser,
  UserState,
} from '@vertice/state';
import { Auth, User, UserProgress } from '@vertice/data';
import {
  tap,
  BehaviorSubject,
  catchError,
  finalize,
  of,
  from,
  Observable,
  filter,
  switchMap,
  throwError,
  map,
  mapTo,
  concatMap,
  delay,
  retryWhen,
  retry,
  take,
  timer,
} from 'rxjs';
import { Router } from '@angular/router';
import { AuthError, Session } from '@supabase/supabase-js';
import { SupabaseService } from 'apps/vertice/src/app/shared/services/supabase.service';

@Injectable({ providedIn: 'root' })
export class AuthService {
  isLoading$ = new BehaviorSubject<boolean>(false);
  // isAuthenticatedSubject = new BehaviorSubject<boolean>(false);
  // isAuthenticated$ = this.isAuthenticatedSubject.asObservable();
  // private refreshingToken = false;
  // private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  
  private authStateSubject = new BehaviorSubject<boolean>(false);
  public authState$ = this.authStateSubject.asObservable();

  private refreshInProgress: boolean = false;
  private refreshSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(
    private store: Store,
    private http: HttpClient,
    private supabaseService: SupabaseService,
    private router: Router,
  ) {
  }

  signIn(email: string, password: string): Observable<void> {
    this.isLoading$.next(true);
    return from(this.supabaseService.signInWithPassword(email, password)).pipe(
      tap(({ data, error }) => {
        if (error) {
          this.isLoading$.next(false);
          throw error;
        }
        const session = data.session;
        if (session) {
          this.store.dispatch(
            new SetToken({
              token: session.access_token,
              refreshToken: session.refresh_token,
            })
          );
          // this.authStateSubject.next(true);
          this.updateAuthState(true);

        } else {
          throw new Error('Session data is missing');
        }
      }),
      // Remove the filter
      switchMap(({ data }) => {
        if (!data.session || !data.user) {
          return throwError(() => new Error('Session or user data is null'));
        }
        return this.store.dispatch([
          new Authenticate({ session: data.session }),
          new SetUser({ user: data.user, session: data.session }),
        ]);
      }),
      switchMap(() => from(this.router.navigate(['/broadcasts']))),

      // Change this to return void
      map(() => undefined),
      catchError((error) => {
        this.isLoading$.next(false);
        return throwError(() => error);
      }),
      finalize(() => this.isLoading$.next(false))
    );
  }



  refreshToken(): Observable<{ success: boolean; session: Session | null }> {
    // console.log('*** REFRESH TOKEN *** Method called');

    if (this.refreshInProgress) {
      // console.log('*** REFRESH TOKEN *** Refresh already in progress');
      return this.refreshSubject.pipe(
        filter(result => result !== null),
        take(1)
      );
    }

    this.refreshInProgress = true;
    this.isLoading$.next(true);

    // Changed: Use the stored refresh token from NGXS store
    const refreshToken = this.store.selectSnapshot(AuthState.getRefreshToken);
    if (!refreshToken) {
      console.log('*** REFRESH TOKEN *** No stored refresh token, aborting');
      this.refreshInProgress = false;
      this.isLoading$.next(false);
      return of({ success: false, session: null });
    }

    return from(this.supabaseService.supabase.auth.refreshSession({ refresh_token: refreshToken })).pipe(
      map(({ data, error }) => {
        if (error) {
          console.error('*** REFRESH TOKEN *** Error:', error);
          throw error;
        }
        if (data.session) {
          console.log('*** REFRESH TOKEN *** Session refreshed successfully');
          // Update token in store
          this.store.dispatch(new SetToken({
            token: data.session.access_token,
            refreshToken: data.session.refresh_token
          }));
          // Update auth state
          this.updateAuthState(true);
          return { success: true, session: data.session };
        } else {
          console.log('*** REFRESH TOKEN *** No session data returned');
          return { success: false, session: null };
        }
      }),
      catchError((error: Error) => {
        console.error('*** REFRESH TOKEN *** Caught error:', error.message);
        // Clear stored token on error
        this.store.dispatch(new ClearToken());
        return of({ success: false, session: null });
      }),
      finalize(() => {
        // console.log('*** REFRESH TOKEN *** Finalized');
        this.refreshInProgress = false;
        this.isLoading$.next(false);
        this.refreshSubject.next(null);
      })
    );
  }


  checkAuthState(): Observable<boolean> {
    // console.log('*** CHECK AUTH STATE *** Checking auth state');
    return this.authState$.pipe(
      take(1),
      switchMap(isAuthenticated => {
        if (!isAuthenticated) {
          return this.refreshToken().pipe(
            map(result => result.success),
            catchError(() => of(false))
          );
        }
        return of(isAuthenticated);
      }),
      // tap(isAuthenticated => console.log('*** CHECK AUTH STATE *** Current auth state:', isAuthenticated))
    );
  }


  updateAuthState(isAuthenticated: boolean): void {
    // console.log('*** UPDATE AUTH STATE *** Setting state to:', isAuthenticated);
    this.authStateSubject.next(isAuthenticated);
  }



  signUp(email: string, password: string) {
    this.isLoading$.next(true);
    return from(this.supabaseService.signUpWithPassword(email, password)).pipe(
      switchMap((response) => {
        console.log('SignUp response:', response);
        const user = response.data?.user;
        const session = response.data?.session;
        if (user) {
          this.store.dispatch(new UnsetUser());
          this.store.dispatch(new SetUser({ user, session }));
          if (session && session.access_token) {
            return this.store
              .dispatch(new Authenticate({ session }))
              .pipe(map(() => ({ user, session })));
          } else {
            throw new Error('Invalid session object');
          }
        } else if (response.error) {
          throw response.error;
        } else {
          throw new Error('Unknown signup error');
        }
      }),
      tap(({ user }) => {
        const isAuthenticated = this.store.selectSnapshot(
          AuthState.isAuthenticated
        );
        if (isAuthenticated && user) {
          this.router.navigate(['/broadcasts']);
        }
      }),
      catchError((error) => {
        this.isLoading$.next(false);
        return throwError(() => error);
      }),
      finalize(() => this.isLoading$.next(false))
    );
  }

  resetPassword(email: string): Observable<void> {
    this.isLoading$.next(true);
    return from(this.supabaseService.resetPasswordForEmail(email)).pipe(
      tap(() => {
        this.isLoading$.next(false);
        // Handle success, e.g., show a success message
      }),
      catchError((error) => {
        this.isLoading$.next(false);
        return throwError(() => error);
      }),
      finalize(() => this.isLoading$.next(false))
    );
  }

  updateUserPassword(newPassword: string): Observable<void> {
    this.isLoading$.next(true);
    return from(this.supabaseService.updateUserPassword(newPassword)).pipe(
      tap(() => {
        this.isLoading$.next(false);
        // Handle success, e.g., show a success message
      }),
      catchError((error) => {
        return throwError(() => error);
      })
    );
  }


  logout(): Observable<{ error: AuthError | null }> {
    this.isLoading$.next(true);
    return from(this.supabaseService.signOut()).pipe(
      tap(() => {
        this.updateAuthState(false);
        sessionStorage.clear();
        // localStorage.removeItem('session');
        this.store.dispatch(new Logout());
      }),
      switchMap(() => {
        return from(this.router.navigate(['/signin'])).pipe(
          map(() => ({ error: null }))
        );
      }),
      catchError((error) => {
        this.isLoading$.next(false);
        console.error('AuthService: Logout failed', error);
        return of({ error });
      }),
      finalize(() => {
        this.isLoading$.next(false);
      })
    );
  }


  // handleTokenExpiration () {
  //   return 'token expired'
  // }

  // getToken(): string | null {
  //   return localStorage.getItem('session');
  // }

  //   logout() {
  //     localStorage.removeItem(AuthToken);
  //     this.store.dispatch(new Logout());
  //     this.store.dispatch(new Navigate(['/login']));
  //   }
  // Implement your OAuth logic here. The following is a placeholder for where your OAuth logic would go.
  handleOAuthLogin(provider: string) {
    // Logic for handling OAuth login
  }
}


// v1
// refreshToken(): Observable<{ success: boolean; session: Session | null }> {
//   if (this.refreshInProgress) {
//     return this.refreshSubject.pipe(
//       filter(result => result !== null),
//       take(1)
//     );
//   }

//   this.refreshInProgress = true;
//   this.isLoading$.next(true);
//  if (!this.hasStoredRefreshToken()) {
//     console.log('*** REFRESH TOKEN *** No stored refresh token, aborting');
//     this.refreshInProgress = false;
//     this.isLoading$.next(false);
//     return of({ success: false, session: null });
//   }

//   return from(this.supabaseService.supabase.auth.refreshSession()).pipe(
//     map(({ data, error }) => {
//       if (error) throw error;
//       if (data.session) {
//         // Update token in store
//         this.store.dispatch(new SetToken({
//           token: data.session.access_token,
//           refreshToken: data.session.refresh_token
//         }));
//         // Update auth state
//         this.authStateSubject.next(true);
//         // Update user in store
//         this.store.dispatch(new SetUser({ user: data.session.user, session: data.session }));
//         const result = { success: true, session: data.session };
//         this.refreshSubject.next(result);
//         return result;
//       } else {
//         const result = { success: false, session: null };
//         this.refreshSubject.next(result);
//         return result;
//       }
//     }),
//     catchError((error) => {
//       console.error('***REFRESH TOKEN AUTH STATE*** Error refreshing token:', error);
//       const result = { success: false, session: null };
//       this.refreshSubject.next(result);
//       return of(result);
//     }),
//     finalize(() => {
//       this.refreshInProgress = false;
//       this.isLoading$.next(false);
//     })
//   );
// }


// v1
// refreshToken(): Observable<{ success: boolean; session: Session | null }> {
//   console.log('*** REFRESH TOKEN *** Method called');

//   if (this.refreshInProgress) {
//     console.log('*** REFRESH TOKEN *** Refresh already in progress');
//     return this.refreshSubject.pipe(
//       filter(result => result !== null),
//       take(1)
//     );
//   }

//   this.refreshInProgress = true;
//   this.isLoading$.next(true);

//   const storedRefreshToken = localStorage.getItem('refreshToken');
//   if (!storedRefreshToken) {
//     console.log('*** REFRESH TOKEN *** No stored refresh token, aborting');
//     this.refreshInProgress = false;
//     this.isLoading$.next(false);
//     return of({ success: false, session: null });
//   }

//   return from(this.supabaseService.supabase.auth.refreshSession()).pipe(
//     map(({ data, error }) => {
//       if (error) {
//         console.error('*** REFRESH TOKEN *** Error:', error);
//         throw error;
//       }
//       if (data.session) {
//         console.log('*** REFRESH TOKEN *** Session refreshed successfully');
//         this.store.dispatch(new SetToken({
//           token: data.session.access_token,
//           refreshToken: data.session.refresh_token
//         }));
//         this.updateAuthState(true);
//         localStorage.setItem('refreshToken', data.session.refresh_token);
//         return { success: true, session: data.session };
//       } else {
//         console.log('*** REFRESH TOKEN *** No session data returned');
//         return { success: false, session: null };
//       }
//     }),
//     catchError((error: Error) => {
//       console.error('*** REFRESH TOKEN *** Caught error:', error.message);
//       localStorage.removeItem('refreshToken');
//       return of({ success: false, session: null });
//     }),
//     finalize(() => {
//       console.log('*** REFRESH TOKEN *** Finalized');
//       this.refreshInProgress = false;
//       this.isLoading$.next(false);
//       this.refreshSubject.next(null);
//     })
//   );
// }
// v1
// checkAuthState(): Observable<boolean> {
//   return this.authState$.pipe(
//     take(1),
//     switchMap(isAuthenticated => {
//       if (!isAuthenticated) {
//         return this.refreshToken().pipe(map(result => result.success));
//       }
//       return of(isAuthenticated);
//     })
//   );
// }

// checkAuthState(): Observable<boolean> {
//   console.log('*** CHECK AUTH STATE *** Checking auth state');
//   return this.authState$.pipe(
//     take(1),
//     tap(isAuthenticated => console.log('*** CHECK AUTH STATE *** Current auth state:', isAuthenticated))
//   );
// }

// v2
// refreshToken(): Observable<{ success: boolean; session: Session | null }> {
//   this.isLoading$.next(true);
//   return from(this.supabaseService.supabase.auth.refreshSession()).pipe(
//     map(({ data, error }) => {
//       if (error) throw error;
//       if (data.session) {
//         // Updated to use existing SetToken action with new tokens
//         this.store.dispatch(new SetToken({
//           token: data.session.access_token,
//           refreshToken: data.session.refresh_token
//         }));
//         this.isAuthenticatedSubject.next(true);
//         return { success: true, session: data.session };
//       } else {
//         // If no session is returned, clear the token
//         this.store.dispatch(new ClearToken());
//         this.isAuthenticatedSubject.next(false);
//         return { success: false, session: null };
//       }
//     }),
//     // Added error handling
//     catchError((error) => {
//       this.isLoading$.next(false);
//       this.isAuthenticatedSubject.next(false);
//       console.error('Error refreshing token:', error);
//       return of({ success: false, session: null });
//     }),
//     // Added finalize to ensure isLoading is set to false
//     finalize(() => this.isLoading$.next(false))
//   );
// }

// v1
// refreshToken(refreshToken: string): Observable<void> {
//   return from(this.supabaseService.refreshToken(refreshToken)).pipe(
//     tap((token) => {
//       if (token) {
//         this.store.dispatch(new SetToken({ token, refreshToken }));
//       } else {
//         this.store.dispatch(new ClearToken());
//       }
//     }),
//     map(() => void 0)
//   );
// }

// v1
// signIn(email: string, password: string): Observable<void> {
//   this.isLoading$.next(true);
//   return from(this.supabaseService.signInWithPassword(email, password)).pipe(
//     tap(({ data, error }) => {
//       if (error) {
//         this.isLoading$.next(false);
//         throw error;
//       }
//       const session = data.session;
//       if (session) {
//         this.store.dispatch(
//           new SetToken({
//             token: session.access_token,
//             refreshToken: session.refresh_token,
//           })
//         );
//         this.authStateSubject.next(true);
//       } else {
//         throw new Error('Session data is missing');
//       }
//     }),
//     // filter(() => !!this.isAuthenticatedSubject.value),
//     switchMap(({ data }) => {
//       if (!data.session || !data.user) {
//         return throwError(() => new Error('Session or user data is null'));
//       }
//       return this.store.dispatch([
//         new Authenticate({ session: data.session }),
//         new SetUser({ user: data.user, session: data.session }),
//       ]);
//     }),
//     switchMap(() => from(this.router.navigate(['/broadcasts']))),

//     // concatMap(({ data }) => {
//     //   if (!data.session || !data.user) {
//     //     return throwError(() => new Error('Session or user data is null'));
//     //   }
//     //   return from(
//     //     this.store.dispatch([
//     //       new Authenticate({ session: data.session }),
//     //       new SetUser({ user: data.user, session: null }),
//     //     ])
//     //   ).pipe(
//     //     concatMap(() => this.store.select(AuthState.getToken)),
//     //     filter((token) => !!token),
//     //   );
//     // }),
//     // switchMap((user) => {
//     //   if (user) {
//     //     return from(this.router.navigate(['/broadcasts'])).pipe(
//     //       map(() => void 0) // Return an observable of void 0 after navigation
//     //     );
//     //   } else {
//     //     return of(void 0); // Return an observable of void 0 if user is null
//     //   }
//     // }),
//     catchError((error) => {
//       this.isLoading$.next(false);
//       return throwError(() => error);
//     }),
//     finalize(() => this.isLoading$.next(false))
//   );
// }
  // mistral
  // signUp(email: string, password: string) {
  //   this.isLoading$.next(true);
  //   return from(this.supabaseService.signUpWithPassword(email, password)).pipe(
  //     switchMap((response) => {
  //       console.log('SignUp response:', response);
  //       const user = response.data?.user;
  //       const session = response.data?.session;
  //       if (user) {
  //         this.store.dispatch(new UnsetUser());
  //         this.store.dispatch(new SetUser({ user, session }));
  //         if (session && session.access_token) {
  //           this.store.dispatch(new Authenticate({ session }));
  //         }
  //         return of(user).pipe(delay(1000));
  //       } else if (response.error) {
  //         throw response.error;
  //       } else {
  //         throw new Error('Unknown signup error');
  //       }
  //     }),
  //     concatMap((user) =>
  //       this.waitForUserProgress(user).pipe(
  //         switchMap((userProgress) => {
  //           this.store.dispatch(
  //             new UpdateUserObjectProgress(user, userProgress)
  //           );
  //           return of(user);
  //         })
  //       )
  //     ),

  //     this.store
  //     .select(GrandObjectiveState.grandObjectives)
  //     .subscribe((grandObjectives) => {
  //       this.grandObjectives$.next(grandObjectives);
  //     });
  // }